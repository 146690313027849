<template>
  <div id="dashboard-content">
    <div class="d-flex">
      <div class="mr-auto">
        <h4>Izin Cetak {{ title }}</h4>
        <div class="small text-muted">
          Harap untuk mencentang semua persyaratan dan pastikan data profil
          sudah dilengkapi.
        </div>
        <div class="small text-muted">
          Data belum lengkap?
          <router-link to="/user/profil/publisher" class="text-primary">
            Lengkapi disini
          </router-link>
        </div>
      </div>
      <nav>
        <ol class="breadcrumb p-0 bg-transparent">
          <li class="breadcrumb-item p-0 m-0">
            <router-link to="/user/izin-cetak" class="text-muted">
              Pilih Kurikulum
            </router-link>
          </li>
          <li class="breadcrumb-item active text-dark" aria-current="page">
            Izin Cetak {{ title }}
          </li>
        </ol>
      </nav>
    </div>

    <div class="card mb-3 border-0 shadow-sm py-1 px-3 mt-4">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">Nama Perusahaan</div>
            <h6>{{ profilePublisher.name }}</h6>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">No telepon</div>
            <h6>{{ profilePublisher.phone }}</h6>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">Alamat</div>
            <h6>{{ profilePublisher.address }}</h6>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">Narahubung</div>
            <h6>{{ profilePublisher.contact_person_phone }}</h6>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">No Whatsapp</div>
            <h6>{{ profilePublisher.phone }}</h6>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">Email</div>
            <h6>{{ profilePublisher.email }}</h6>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">NIB</div>
            <h6>{{ profilePublisher.nib }}</h6>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="text-muted mb-2">Lampiran NIB</div>
            <h6>
              <a
                target="_blank"
                :href="profilePublisher.siup"
                class="text-primary"
              >
                {{ profilePublisher.siup }}
              </a>
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="$store.state.messageStatusReport"
      class="alert alert-success"
      role="alert"
    >
      Laporan berhasil dibuat <i class="fa fa-check"></i>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="mt-4 p-3 bg-primary-50 rounded">
      <h5>Syarat & Ketentuan</h5>
      <ol>
        <li>Mencantumkan harga buku pada cover belakang.</li>
        <li>
          Menampilkan identitas penerbit/percetakan pada halaman hak cipta
        </li>
        <li>
          Tidak mengubah konten/materi dan spesifikasi teknis buku sesuai dengan
          SK Kepala BSKAP Nomor : 020/H/P/2022 tentang penetapan HET Buku Teks
          Pelajaran Kurikulum Merdeka.
        </li>
      </ol>
      <form @submit.prevent="sendPermission()" accept-charset="utf-8">
        <div class="form-check">
          <input
            v-model="accept_tnc"
            class="form-check-input"
            type="checkbox"
            id="defaultCheck1"
          />
          <label class="form-check-label" for="defaultCheck1">
            Dengan ini saya memahami dan menyetujui syarat & ketentuan yang
            berlaku pada poin-poin di atas
          </label>
        </div>
        <div class="text-right">
          <button
            v-if="$store.state.loadPage"
            class="btn btn-sm btn-secondary text-white rounded-pill px-5"
            disabled
          >
            <span class="spinner-border spinner-border-sm"></span>
            Sedang diproses..
          </button>
          <button
            v-else
            :disabled="!accept_tnc"
            type="submit"
            class="btn btn-sm btn-secondary text-white rounded-pill px-5"
          >
            Kirim
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { BASE_URL } from "@/store";
import Swal from "sweetalert2";
export default {
  name: "Izin Cetak",
  data() {
    return {
      type: window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      ),
      title: "",
      profilePublisher: [],
      accept_tnc: false,
      messageSuccess: "",
    };
  },
  methods: {
    ...mapActions(["sendPrintPermission"]),
    sendPermission() {
      this.sendPrintPermission(this.type)
        .then((res) => {
          if (res.data.status == "success") {
            console.log(res);
            Swal.fire({
              imageUrl:
                "https://ik.imagekit.io/8jggdaymrs/app.buku.kemdikbud.go.id/Done.png",
              imageAlt: "Done",
              title: "<h4>Izin cetak berhasil dikirim</h4>",
              text: "Harap menunggu informasi terkait izin cetak Anda",
            });
          }
        })
        .catch((err) => console.log(err));
      },
  },
  beforeCreate() {
    const logged_in = localStorage.getItem("user");
    if (!logged_in) {
      this.$router.push("/login");
    }
  },
  created() {
    switch (this.type) {
      case "merdeka":
        this.title = "Kurikulum Merdeka";
        break;
      case "k13":
        this.title = "Kurikulum K-13";
        break;
      default:
        this.title = "Non Teks";
        break;
    }

    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}api/user/getPublisherProfile`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.profilePublisher = res.data.result;
        if (res.data.result.print_permission !== null) {
          this.accept_tnc = true
        }
      });
  },
  beforeRouteEnter(_, __, next) {
    const { role_name } = JSON.parse(localStorage.getItem("user"));
    if (role_name === "Penerbit" || role_name === "Pencetak") next();
    else next("/user/home");
  },
};
</script>
